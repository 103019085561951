$darkGrey : #5f5e5e;
$lighterGrey : lighten($darkGrey,50%);

$blue: #5782e8;
$green: #17a454;
$orange: #ff9d02;
$red: #dc0101;

@import "inc/normalize";
@import "inc/utils";
@import "inc/grid";
@import "inc/layout";
@import "inc/typography";
@import "inc/menu";
@import "inc/banner";
@import "inc/buttons";
@import "inc/forms";
@import "inc/tables";
@import "inc/navigation";
@import "inc/textonly";
@import "inc/phone";
@import "inc/join";
