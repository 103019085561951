body {


	//min-height: 200vh;

	// @include mq(tablet) {
	// 	background: green;
	// 	color: white;
	// 	padding: 100px;
	// }

	// @include mq(500px) {
	// 	background: grey;
	// }


	&.flowOnly {
		.scroll-section { opacity: 1;}
		.background { display: none !important;}
		.introduction { display: none !important;}
		.logo-container { display: none !important;}
	}

	&.locked {
		height: 100vh;
		overflow: hidden;
	}
}

* {
	font-weight: 300;
}


p, span, a, h1,h2,h3,h4,h5,h6 {

	&.wideCopy{
		letter-spacing: 5px;
	}
}
 a {
	 color: white;
 }

@include mq(mobile) {
	.desktopOnly {
		display: none !important;
	}
}

.mobileOnly {
	display: none;
	@include mq(mobile) {
		display: block;
	}
}



.scroll-section {
	opacity: 0;
	height: 100vh;
	position: relative;
	z-index: -50;
	&.half { height: 50vh; }
}
#scroll-section-4 {
	height: 200vh;
}

.background {
	background: $blue ;//$darkGrey ;//url('../img/background.jpg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
	z-index: -100;
	top:0; left:0;
	height:100vh; width:100vw;

	img {
		opacity: 0;
		min-width: 100%;
		min-height: 100%;
		position: absolute;
		top:50%; left:54%;
		transform: translate(-50%,-50%);
		&.einstein {  z-index: 10;}
		&.blocks { z-index: 20;}
		&.hide { display: none;}

		&.mobile-image {
			width: 100% !important;
			max-width: 100% !important;
			top:0 !important; left: 6px !important;
			min-height: 0px !important;
			transform: none !important;
		}
	}
}


.introduction {
//@extend .section;
	@extend .wideCopy;
	color: white !important;
	position: fixed;
	top:0; left:0;
	height: 100vh; width:100vw;
	opacity:1;


	.introContent {
		//background: purple;
		width: 60%;
		height: 50vh;
		position: absolute;
		top:30%; left:50%;
		transform: translateX(-50%);//translate(-50%,-50%);
		text-align: center;
		&.shortScreen {
			height: 70vh; top: 15%;
		}
		@include mq(mobile) {
			// width: 100%;
			// height: 80vh;
			// top:10%;
			display: none;
		}

		.inner {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 90%;
			.partnership-container p {
				@include mq(mobile) {
					font-size: 4vw;
				}
			}
			@include mq(mobile) {
				justify-content: space-around;
			}
		}

		// img{ width: 25%; margin:0;float: left;}

		.logos {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			width: 90%;
			margin-top: 3em;
			//margin-top: 50px;
			@include mq(mobile) {
				flex-direction: column;
				width: 100%;
				padding: 0 10%;
				box-sizing: border-box;
				margin-top: 15%;
			}
		}

		.intro-text {
			@extend .wideCopy;
			margin-bottom: 30px;
			@include mq(mobile) {
				font-size:4vw;
			}
		}

		.partnership-container {
			//margin-top: 100px;
		}

		.intro-gradient {
			display: block;
			width: 50%;
			margin: 0 auto;
			&.bottom { transform: rotate(180deg);}
		}
	}

	.introContentMobile {
		display: none;
		@include mq(mobile) {
			margin-top: 15vh;
			display: block;
			width: 100%;
			height: 100%;
			//background: orange;
			img {
				max-height: 100%;
				max-width: 100%;
				display: block;
				margin: 0 auto;
			}
			.coalition-logos-mobile {
				box-sizing: border-box;
				padding: 3em;
				max-height: 60vh;
			}
			.seperator {
				display: block;
				margin: 0 auto 1em auto;
			}
			.chevron {
				position: fixed;
				bottom: 5vh;
				left: 50%;
				-webkit-transform: translateX(-50%);
				transform: translateX(-50%)
			}
			h2 {
				margin-bottom: 1em;
			}
			.real-play-title {
				text-align: center !important;
			}
		}
	}
}



.italic {
	font-style: italic;

}

.group-logo {
	&:not(:last-of-type){
		//margin-right: 40px;
	}
	padding: 1em;
	margin-bottom: 70px;
	display: inline-block;
	margin: 0 auto 70px auto;

	//max-width: 250px;
	&.lego {
		//width: 800px;
		@include mq(mobile) {
			max-width:50vw;
		};
	}
	&.ikea {
		max-width: 200px;
		@include mq(mobile) {
			max-width:40vw;
		};
	}
	&.uni {
		max-width: 100px;
		@include mq(mobile) {
			max-width: 30vw;
		};
	}
	&.natgeo {
		max-width: 160px;
		@include mq(mobile) {
			max-width: 40%;
		};
	}
}

.section {
	min-height: 100vh;
	width: 100%;
	background: transparent;
	position: relative;
	body.devMode & { border-top: solid 1px white; }
}

.logo-container {
	position: fixed; z-index: 1000;
	height: 100vh; width:100vw;
	//width:75vw; padding-bottom:100%;
	top: -100%; left:0;
	// &.animating {
	// 	position: fixed;
	// 	top:0; left:0;
	// }
	svg {
		position: absolute;
		top: 50%;
    	left: 50.15%;
		transform: translate(-50%,-50%);
		width: 37%;
		overflow: visible;
		@include mq(mobile) {
			width: 80%;
		}
	}
}

.logo-elements {
	transform: translateX(2px);
}

.strapline {
	@extend .wideCopy;
	color: white;
	display: none;// inline-block;
	position: absolute;
	top:48%; left:50%;
	letter-spacing: 2px;

	.light {
		font-weight: 300;
		font-size: 1.2em;
	}
	.bold {
		font-weight: 600;
		font-size: 1.5em;
	}
	&.transformed {
		//transform: translate(-50%,15vh);
		transform: translateY(15vh);
	}
}

.strapline-group {
	//opacity: 1;
	path {
		opacity: 1;
	}
}

.subheading-container {
	position: absolute;
	bottom: 60px; //  top:73%;
	left: 50%;
	transform: translateX(-50%);
	color: white;
	display: block;
	width: 75%;
	text-align: center;
	@include mq(mobile) {
		width: 100%;
		text-align: left;
		left: 0 !important;
		// font-size: 2.5em;
		padding: 0 10%;
		box-sizing: border-box;
		transform: none;
	}
	&.shortScreen {
		bottom: 0px;
		.subheading , .subheading2 {
			font-size: 16px;
		}
	}

	.subheading {
		margin-bottom: 20px;

	}
	.subheading , .subheading2 {
		opacity: 0;
		display: block;
		&.shortScreen {
			font-size: 16px;
		}
	}
}



.tooltip {
	min-width: 100px;
	left: 25px;
	position: absolute;
	transform: translateY(-50%);
	color: white;
	line-height: 100%;
	opacity: 0;
	transition: opacity 0.25s, left 0.4s;
	&.show {
		opacity: 1;
		left: 15px;
	}
}

.trigger {
	background: purple;
	color: white !important;
}

#trigger-scene-2 {
	position: absolute;
	bottom:0;
}
// #trigger-scene-3 {
// 	position: absolute;
// 	bottom:50%;
// }

#content-block {
	height: 100vh; width: 100vw;
	position: fixed;
	top:110%; left:0;
	z-index: 4000;
	opacity: 0;
	&.shortScreen {
		.einstein-shapes {
			height: 65px;
			padding: 10px 0;
		}
		.download-blocks {
		    margin-bottom: 1em;
		}
		.left-half {
			//margin-top: 5vh;
		}
		.right-half {
			justify-content: space-around !important;
			// &.tallScreen {
            //
			// 	margin-top: 6em;
			// }
		}
	}

	.terms-main-sec-container {
		margin-top: 1em;

	}

	// .content-inner {
	// 	width: 75%;
	// 	height: 75%;
	// 	text-align: center;
	// 	//display: flex;
	// 	//flex-direction: column;
	// 	//justify-content: center;
	// 	position: absolute;
	// 	bottom:0; left:50%;
	// 	transform: translateX(-50%);
	// 	color: white;
	// 	display: none;
	// 	&.active { display: block;}
	// }
	@include mq(mobile) {
		opacity: 1;
		height: auto;
		overflow: auto;
		padding:5px 0;
	}
}

$swipeTransitionSpeed: 1s;

#content-scroll-wrapper {
	width: 75%;
	height: 100%;
	text-align: center;
	//display: flex;
	//flex-direction: column;
	//justify-content: center;
	position: absolute;
	bottom:0; left:50%;
	transform: translateX(-50%);
	@include mq(mobile) {
		width: 100%;
	}

	//overflow: hidden;
	.content-scroll-track {
		width: 200%;
		height: 100%;
		position: absolute;
		left:0; // 0 to -100% for transition.
		transition: left 1s;
		&.scrollable {
			overflow: scroll;
		}
		// @include mq(mobile) {
		// 	width: 100%;
		// }

		&.movedRight {
			left:-100%;
			.half:first-of-type {
				opacity: 0;
			}
			.half:last-of-type {
				opacity: 1;
			}
		}
		.half:first-of-type {
			opacity: 1;
		}
		.half:last-of-type {
			opacity: 0;
		}


		.half {
			transition: opacity $swipeTransitionSpeed;
			width: 50%;
			height: 100%;
			float: left;
			color: white;
			box-sizing: border-box;
			padding: 0 5%;
			display: flex;
    		flex-direction: column;
    		justify-content: space-around;
			&.right-half {
				padding: 0 0 20px 0;
				justify-content: flex-end;
				&.tallScreen {
					justify-content: space-around;
					margin-top: 6em;
				}
			}
			@include mq(mobile) {
				padding: 0;
				display: block;
				overflow: scroll;
				.half-inner {
					width: 100vw;
    				box-sizing: border-box;
					height: auto;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					padding: 0 5%;
				}
			}
			// @include mq(mobile) {
			// 	padding: 0;
			// 	width: 100%;
			// 	opacity: 1 !important;
			// 	height: auto !important;
			// 	.half-inner {
			// 		padding: 0 10%;
			// 	}
			// }
			p , span {
				@include mq(mobile) {
					font-size: 2.3em;
					text-align: center;
				}
			}
		}
	}
}

#mobile-content {
	@extend %group;
	position: relative;
	box-sizing: border-box;
	//background: purple;
	height: auto;
	padding: 0 5%;
	color: white;
	* { float: left;}
	//div { float: left;}
	a {
		display: block;
		width: 100%;
	}
	p { margin: 0.5em 0; }
	.footer {
		display: block;
		width: 100%;
	}
	.shapes {
		margin: 2em 0;
	}
	.mobile-share {
		* { float: none !important;}
		position: relative !important;
		display: block;
		width: 100%;
		min-height:100px;
		text-align: center;
		padding: 25px 0 50px 0;
		a {
			width: 50%;
			display: inline;
			&:first-of-type { margin-right: 10px; }
			img {
				width: 40px;
				height: 40px;

			}
		}
	}
	//p { margin:0; padding:0; }
}

.thin {
	text-align: left !important;
	width: 100% !important;
	margin: 50px auto;
}

.chev {
	max-width: 20px;
	position: relative;
	top: 4px;
	&.right { transform: rotate(180deg);}
}

// p.withPadding {
// 	margin: 50px 0;
// }

//
.contentToggle {
	color: white;
	cursor: pointer;
	display: block;

	&:hover {
		img.right {
			animation: bumpChevronRight 0.5s;
			animation-iteration-count:infinite;
			animation-timing-function: linear;
			animation-direction: alternate;
		}
		img.left {
			animation: bumpChevronLeft 0.5s;
			animation-iteration-count:infinite;
			animation-timing-function: linear;
			animation-direction: alternate;
		}
	}
}

@keyframes bumpChevronRight {
	0% { transform: translateX(0%) rotate(180deg); }
	100% { transform: translateX(20%) rotate(180deg); }
}
@keyframes bumpChevronLeft {
	0% { transform: translateX(0%); }
	100% { transform: translateX(-20%); }
}

// @keyframes spinIt {
// 	0% { transform: translate(-50%,-50%) rotate(0deg); }
// 	100% {transform: translate(-50%,-50%) rotate(360deg);}
// }

// .shapes {
// 	max-width: 40%;
// 	@include mq(mobile) {
// 		max-width: 90%;
// 	}
// }
.mail {
	max-width: 30px;
	position: relative;
    top: 4px;
    padding-right: 5px;
	&:hover {
		color: white;
	}
}


button.download {
	display: inline-block;
	background: grey;
	border: solid 3px black;
	border-radius: 0;
	color: white;
}

.chevron {
	position: fixed;
	margin: auto;
	left: 0;
	right: 0;
	bottom: 2em;
	transform: rotate(180deg);
	@include mq(mobile) {
		max-width: 20px;
	}
}

.progress-bar {
	height: 80vh;
	width: 1px;
	background: rgba(255,255,255,0.1);
	position: fixed;
	z-index: 2000;
	top: 10vh;
	left:40px;

	.scrollPoint {
		position: relative;
		top:0;
		height: 16px; width:16px;
		background: url('../img/scrollpoint.svg');
		background-size: cover;
		transform: translate(-50%,-50%);
		overflow: hidden;
	}

	@include mq(mobile) {
		display: none;
	}
}

#inner-block-2 {
	display: none;
}

.form-container {
	position: relative;
	margin-top: 2em;




	@include mq(mobile) {
		margin-top: 0;
	}
}

#signUpForm {
	transition: opacity 0.5s;
	opacity: 1;
	&.sent {
		opacity: 0;
	}
}
#signUpForm-mobile {
	margin-top: 2em;
	transition: opacity 0.5s;
	opacity: 1;

	&.sent {
		opacity: 0;
		height: 0;
		overflow: hidden;
	}
	.opt-in {
		margin-top: 1em;
	}
}


#thankYou {
	position: absolute;
	top: 50%;
	left:50%;
	transform: translate(-50%,-50%);
	transition: opacity 0.5s;
	opacity: 0;
	&.sent {
		opacity: 1;
	}
}

#thankYou-mobile {
	opacity: 0;
	transition: opacity 0.5s;
	width: 100%;
	text-align: center;
	padding: 10px 0;
	&.sent {
		opacity: 1;
		h3 { float: none; text-align: center;}
	}
}

.fauxRadioBox {
	display: inline-block;
	height: 15px;
	width: 15px;
	border: solid 2px white;
	cursor: pointer;
	&.choice {
		background: white;
	}
}


.fauxCheckbutton {
	@extend .fauxRadioBox;
	transition: background 0.2s;
	position: relative;
    top: 3.5px;
	//float: left;
	margin-right: 10px;
}


.terms-container {
	padding: 0 5%;
	.terms-logo {
		width: 200px;
		margin-top: 50px;
	}
	a {
		color: black;
	}
}

.share {
	position: fixed;
	z-index: 6000;
	right: 30px;
	bottom: 30px;
	//width: 100px;
	//height: 40px;
	display: inline-block;
	ul {
		list-style-type: none;
		padding:0; margin:0;
		li {
			float: left;
			&:not(:last-of-type){ margin-right: 10px; }
			color: white;
			font-size: 0.7em;
		}
	}
	img {
		height: 15px;
		width: 15px;
	}
}

//Einstein images

.einstein-shapes {
	padding: 2em 0;
	max-width: 100%;
	margin: 0 auto;
	display: block;
}

.turnDeviceNotification {
	position: fixed;
	top:0; right:0; bottom:0; left:0;
	z-index: 100000;
	background: white url('../img/rotate.png') no-repeat;
	background-position: center;
	display: none;
}
