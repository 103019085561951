/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  font-size: 16px;
  height: 38px;
  padding: 5px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 0 !important;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 1em;
    @include mq(mobile) {
        display: block;
		margin-bottom: 1em;
        width: 100%;
        border: solid 1px white;
        font-size: 14px;
		padding: 1em 0.5em;
    }
}
/* Removes awkward default styles on some inputs for iOS */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px; }
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border: 1px solid #33C3F0;
  border-radius: 0 !important;
  outline: 0; }
label,
legend {
  display: inline-block;
  margin-bottom: .5rem;
  text-align: left;
  font-weight: 600; }
fieldset {
  padding: 0;
  border-width: 0; }
input[type="checkbox"],
input[type="radio"] {
  display: inline; }
label > .label-body {
  display: inline-block;
  margin-left: .5rem;
  font-weight: normal; }
.padding20px {
    padding: 20px 0;
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: white;
}
::-moz-placeholder { /* Firefox 19+ */
  color: white;
}
:-ms-input-placeholder { /* IE 10+ */
  color: white;
}
:-moz-placeholder { /* Firefox 18- */
  color: white;
}

#error-container , #error-container-mobile {
    background: red;
    color: white;
    text-align: center;
	padding: 0.5em;
    display: none;
	background-color: #de3c3c;
    &.errors {
		margin-top: 0;
		margin-bottom: 2em;
        display: block;
		@include mq(mobile) {
			margin: 0;
		}
    }
}

.opt-in-container {
	margin-bottom: 1.5em;
}

.opt-in {
	display: inline;
	margin-bottom: 1.5em;
}

.terms-link {
	margin: 1em 0 0.5em 0;
}
