.phone-panel {
    height: 100vh;
    background: red;

    &.mob-introduction {
        img {
            max-height: 100vh;
        }
    }
}
