/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
    display: inline-block;
    padding: 5px 30px;
    color: white;
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: .1rem;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border-radius: 0;
    border: 1px solid white;
    cursor: pointer;
    box-sizing: border-box;
    transition: background-color 0.5s , color 0.5s;
    @include mq(mobile) {
        width: 100%;
        border: solid 2px white;
        font-size: 4vw;
    };
}
.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
    //color: #333;
    outline: 0;
    background-color: white;
    color: $green;
}
.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
    color: #FFF;
    background-color: #33C3F0;
    border-color: #33C3F0;
}
.button.button-primary:hover,
button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type="submit"].button-primary:focus,
input[type="reset"].button-primary:focus,
input[type="button"].button-primary:focus {
    color: #FFF;
    background-color: #1EAEDB;
    border-color: #1EAEDB;
}

.join-button {
	margin-bottom: 1em;
}

.download-blocks {
	margin-bottom: 2em;
}

.go-to-join {
    margin-top: 2em;
}
