html.join {
    height: 100%;
}


body.join {
    height: 100%;
    background: $green;
    position: relative;
}
.join-container {
    background: $green;
    padding: 15px;
    color: white;
    // position: absolute;
    // top:50%;
    // transform: translateY(-50%);

    .join-logo {
        max-width: 40%;
        margin-bottom: 30px;
    }

    #signUpForm-mobile {
        margin-top: 0;
    }

    .footer-link {
        font-size: 14px;
        display: block;
        text-align: center;
    }

    .back {
        display: block;
        text-align: center;
        text-decoration: none;
        margin-top: 10%;
        font-size: 18px;
    }
}
