/* Add group class to container to clear floated children */
%group:after {
    content: "";
    display: table;
    clear: both;
}

/* Stop user from selecting chosen element */
%no-sel {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

/* Vertically and Horizontally center an element */
%center-both {
    position: relative;
    left:50%; top: 50%;
    transform: translate(-50%,-50%);
}


/* Media Query be defined up in here
E.G - .element { @include mq(tablet) { tablet only code goes here } }
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@mixin mq($breakpoint) {
    // @if $breakpoint == tablet {
    //     @media (min-width: 768px) and (max-width:991px) { @content; }
    // }
    @if $breakpoint == mobile {
        @media (max-width:767px) { @content; }
    }

    @else {
        @media (max-width:$breakpoint) { @content; }
    }
}
// d) The Standard Bootstrap 4.x Breakpoints
// @media all and (max-width: 1199px) { ... }
// @media all and (max-width: 991px) { ... }
// @media all and (max-width: 768px) { ... }
// @media all and (max-width: 575px) { ... }
//

// FONT CONTROL by EMIL
//--------------------------------//
/* Allows a range of font sizes to be set and a range of viewport resolutions across which these will scale.
usage;

.css-selector{
    some code...
    @include liveFont(minimum font size , maximum font size , smallest browser width , widest browser width );
}

    Values should be included WITHOUT 'px'.
*/
@mixin liveFont($minFont , $maxFont , $minWidth , $maxWidth)
{
   @media ( min-width: #{$minWidth}px ) and (max-width: #{$maxWidth}px) {
        font-size: calc(#{$minFont}px + ( #{$maxFont} - #{$minFont} ) * ((100vw - #{$minWidth}px) / ( #{$maxWidth} - #{$minWidth} ))) !important;
   }
   @media ( min-width: #{$maxWidth}px ){
        font-size: #{$maxFont}px !important;
   }
}

// // Retina
$retina: 2;
@mixin resolution($ratio: 1.3) {
    @media only screen and (-webkit-min-device-pixel-ratio: $ratio),
        only screen and (min--moz-device-pixel-ratio: $ratio),
        only screen and (-o-min-device-pixel-ratio: #{$ratio}/1),
        only screen and (min-resolution: round($ratio * 96dpi)),
        only screen and (min-resolution: $ratio * 1dppx) {
        @content;
    }
}

// Scrollbars
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
// For Google Chrome
    #question-carousel-container::-webkit-scrollbar {
        width:  $size;
        height: $size;
    }

    #question-carousel-container::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius:20px;
        border:solid 2px #00366E;
    }

    #question-carousel-container::-webkit-scrollbar-track {
        background: $background-color;
        border-radius:20px;
    }

 // For Internet Explorer
    #answer-content {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
        scrollbar-arrow-color: $background-color;
        //-ms-overflow-style: -ms-autohiding-scrollbar;
    }
}
