/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
* {
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
}
// @include mq(mobile) {
// 	p,
// 	span,
// 	label
// 	{
// 		font-size: 4vw;
// 	}
// };

p {
	font-size: 16px !important;
	@include mq(mobile) {
		font-size: 14px;
	}
}

h2 {
	font-size: 20px;
	font-weight: 500;
	@include mq(mobile) {
		font-size: 20px;
		display: block;
		width: 100%;
	}
}

.subheading, .subheading2 {
	font-size: 1.5em;
	@include mq(mobile) {
		font-size: 1em;
	}
}

// h1 {
// 	@include liveFont(18 , 36 , 0 , 1200);
//
// 	@include mq(tablet) {
// 		color: black;
// 	}
// }
//
// h2 {
// 	@include liveFont(14 , 30 , 0 , 1200);
// 	color: white;
//
// 	@include mq(mobile) {
// 		color: turquoise;
// 	}
// }
